"use strict";
exports.__esModule = true;
var bulma_toast_1 = require("bulma-toast");
var Main = /** @class */ (function () {
    function Main() {
        var self = this;
        self.initEventHandlers();
    }
    Main.prototype.initEventHandlers = function () {
        var self = this;
        $(document).on('change', '#selectRepair input[type="checkbox"]', function (e) {
            var gesamtPreis = 0;
            var selectedOptions = $('#selectedOptions');
            selectedOptions.empty(); // Verhindert doppelte Einträge
            var eligibleRepairs = []; // Array für Reparaturen über 100€
            // Iteriere über alle ausgewählten Checkboxen
            $('#selectRepair input[type="checkbox"]:checked').each(function () {
                var fullText = $(this).parent().text().trim(); // Gesamter Text (inkl. Bezeichnung + Preis)
                // Entferne den Text von Elementen mit der Klasse 'ignore'
                $(this).parents().find('.ignore').each(function () {
                    fullText = fullText.replace($(this).text().trim(), ''); // Entfernt den Text des Elements mit der Klasse 'ignore'
                });
                // Preis herausfiltern (nimmt den letzten Preis mit * raus)
                var preisMatch = fullText.match(/(\d{1,3}(?:[.,]\d{2})?)\s*€\s*\*/);
                var preisText = preisMatch ? preisMatch[0].trim() : "Auf Anfrage";
                // Bezeichnung = voller Text ohne den Preis
                var bezeichnung = fullText.replace(preisText, '').trim();
                // Preis in eine Zahl umwandeln
                var preis = preisText === "Auf Anfrage" ? 0 : parseFloat(preisText.replace(/[^\d.,]/g, '').replace(',', '.'));
                if (preis < 5) {
                    preis = 0;
                }
                gesamtPreis += preis;
                // Überprüfe, ob der Preis über 100€ liegt und speichere die Reparatur
                if (preis > 50) {
                    eligibleRepairs.push(preis);
                }
                // Füge die Reparatur zur Liste hinzu (Text links, Preis rechts)
                selectedOptions.append("\n                    <div class=\"option-item\" data-id=\"".concat($(this).attr('id'), "\" style=\"display: flex; justify-content: space-between; width: 100%; padding: 2px 0;\">\n                        <span style=\"text-align: left; flex-grow: 1;\">").concat(bezeichnung, "</span>\n                        <span style=\"text-align: right; white-space: nowrap;\">").concat(preisText, "</span>\n                    </div>\n                "));
            });
            // Wenn 2 oder mehr Reparaturen über 100€ liegen, 5% Rabatt gewähren
            if (eligibleRepairs.length >= 2) {
                var rabatt = gesamtPreis * 0.05; // 5% Rabatt
                gesamtPreis -= rabatt; // Rabatt vom Gesamtpreis abziehen
                $('#rabattInfo').text("Kombi-Rabatt (5%): -".concat(rabatt.toFixed(2).replace('.', ','), " \u20AC"));
            }
            else {
                $('#rabattInfo').text(''); // Keine Rabattmeldung, wenn weniger als 2 Reparaturen über 100€
            }
            // Zeige die Gesamtsumme an
            $('#gesamtPreis').text(gesamtPreis.toFixed(2).replace('.', ',') + ' €');
            // Entferne die Option, wenn sie abgewählt wird
            $('#selectRepair input[type="checkbox"]').on('change', function () {
                if (!this.checked) {
                    $("#selectedOptions .option-item[data-id=\"".concat($(this).attr('id'), "\"]")).remove();
                }
            });
        });
        $(document).on('change', '#selsectRepair input[type="checkbox"]', function (e) {
            var gesamtPreis = 0;
            var selectedOptions = $('#selectedOptions');
            selectedOptions.empty(); // Verhindert doppelte Einträge
            // Iteriere über alle ausgewählten Checkboxen
            $('#selectRepair input[type="checkbox"]:checked').each(function () {
                var fullText = $(this).parent().text().trim(); // Gesamter Text (inkl. Bezeichnung + Preis)
                // Entferne den Text von Elementen mit der Klasse 'ignore'
                $(this).parents().find('.ignore').each(function () {
                    fullText = fullText.replace($(this).text().trim(), ''); // Entfernt den Text des Elements mit der Klasse 'ignore'
                });
                // Preis herausfiltern (nimmt den letzten Preis mit * raus)
                var preisMatch = fullText.match(/(\d{1,3}(?:[.,]\d{2})?)\s*€\s*\*/);
                var preisText = preisMatch ? preisMatch[0].trim() : "Auf Anfrage";
                // Bezeichnung = voller Text ohne den Preis
                var bezeichnung = fullText.replace(preisText, '').trim();
                // Preis in eine Zahl umwandeln
                var preis = preisText === "Auf Anfrage" ? 0 : parseFloat(preisText.replace(/[^\d.,]/g, '').replace(',', '.'));
                if (preis < 5) {
                    preis = 0;
                }
                gesamtPreis += preis;
                // Füge die Reparatur zur Liste hinzu (Text links, Preis rechts)
                selectedOptions.append("\n                    <div class=\"option-item\" data-id=\"".concat($(this).attr('id'), "\" style=\"display: flex; justify-content: space-between; width: 100%; padding: 2px 0;\">\n                        <span style=\"text-align: left; flex-grow: 1;\">").concat(bezeichnung, "</span>\n                        <span style=\"text-align: right; white-space: nowrap;\">").concat(preisText, "</span>\n                    </div>\n                "));
            });
            // Zeige die Gesamtsumme an
            $('#gesamtPreis').text(gesamtPreis.toFixed(2).replace('.', ',') + ' €');
            // Entferne die Option, wenn sie abgewählt wird
            $('#selectRepair input[type="checkbox"]').on('change', function () {
                if (!this.checked) {
                    $("#selectedOptions .option-item[data-id=\"".concat($(this).attr('id'), "\"]")).remove();
                }
            });
        });
        $(document).on('change', '#selectRepsair input[type="checkbox"]', function (e) {
            var gesamtPreis = 0;
            var selectedOptions = $('#selectedOptions');
            selectedOptions.empty(); // Verhindert doppelte Einträge
            // Iteriere über alle ausgewählten Checkboxen
            $('#selectRepair input[type="checkbox"]:checked').each(function () {
                var fullText = $(this).parent().text().trim(); // Gesamter Text (inkl. Bezeichnung + Preis)
                // Preis herausfiltern (nimmt den letzten Preis mit * raus)
                var preisMatch = fullText.match(/(\d{1,3}(?:[.,]\d{2})?)\s*€\s*\*/);
                var preisText = preisMatch ? preisMatch[0].trim() : "Auf Anfrage";
                // Bezeichnung = voller Text ohne den Preis
                var bezeichnung = fullText.replace(preisText, '').trim();
                // Preis in eine Zahl umwandeln
                var preis = preisText === "Auf Anfrage" ? 0 : parseFloat(preisText.replace(/[^\d.,]/g, '').replace(',', '.'));
                if (preis < 5) {
                    preis = 0;
                }
                gesamtPreis += preis;
                // Füge die Reparatur zur Liste hinzu (Text links, Preis rechts)
                selectedOptions.append("\n                    <div class=\"option-item\" data-id=\"".concat($(this).attr('id'), "\" style=\"display: flex; justify-content: space-between; width: 100%; padding: 2px 0;\">\n                        <span style=\"text-align: left; flex-grow: 1;\">").concat(bezeichnung, "</span>\n                        <span style=\"text-align: right; white-space: nowrap;\">").concat(preisText, "</span>\n                    </div>\n                "));
            });
            // Zeige die Gesamtsumme an
            $('#gesamtPreis').text(gesamtPreis.toFixed(2).replace('.', ',') + ' €');
            // Entferne die Option, wenn sie abgewählt wird
            $('#selectRepair input[type="checkbox"]').on('change', function () {
                if (!this.checked) {
                    $("#selectedOptions .option-item[data-id=\"".concat($(this).attr('id'), "\"]")).remove();
                }
            });
        });
        $(document).on('change', '#selectRepaisr input[type="checkbox"]', function (e) {
            var gesamtPreis = 0;
            // Iteriere über alle ausgewählten Checkboxen
            $('#selectRepair input[type="checkbox"]:checked').each(function () {
                // Hole den Text des nächsten Elements und prüfe auf "Auf Anfrage"
                var preisText = $(this).next().text().trim();
                // Wenn der Text "Auf Anfrage" ist, setze den Preis auf 0
                var preis = preisText === "Auf Anfrage" ? 0 : parseFloat(preisText.replace(/[^\d.,]/g, '').replace(',', '.'));
                if (preis < 5) {
                    preis = 0;
                }
                console.log(preisText, preis); // Zum Testen, den Text und den berechneten Preis ausgeben
                // Addiere den Preis zur Gesamtsumme
                gesamtPreis += preis;
            });
            // Zeige die Gesamtsumme auf der Seite an
            $('#gesamtPreis').text(gesamtPreis.toFixed(2).replace('.', ',') + ' €');
        });
        $(document).on('keyup', '#searchGeraet', function (e) {
            var search = this.value.trim().toLowerCase(); // Suchbegriff ohne Leerzeichen und in Kleinbuchstaben
            $('#devicesList > div').each(function () {
                var deviceId = $(this).attr('id').toLowerCase(); // ID des aktuellen Div-Elements
                // Überprüfen, ob die ID dem Suchbegriff ähnlich ist
                if (deviceId.includes(search)) {
                    $(this).addClass('searchVisib'); // Div anzeigen, wenn die ID dem Suchbegriff ähnlich ist
                }
                else {
                    $(this).removeClass('searchVisib'); // Div ausblenden, wenn die ID nicht dem Suchbegriff ähnlich ist
                }
                console.log(deviceId);
            });
            console.log(search);
        });
        $(document).on('click', '.accordionAction', function (e) {
            e.preventDefault();
            var text = $(this).next('#collapsible-card');
            if (text.is(':visible')) {
                text.hide();
            }
            else {
                text.show();
            }
        });
        $(document).on('click', '.sendContact', function (e) {
            e.preventDefault();
            var verified = false;
            var failPoints = 0;
            var name = $(document).find('#name');
            var email = $(document).find('#email');
            var handymodell = $(document).find('#modell');
            var defekt = $(document).find('#defekt');
            var nachricht = $(document).find('#nachricht');
            var agb = $(document).find('#agb').is(':checked');
            if (!name.val()) {
                failPoints = failPoints + 1;
                name.addClass('is-danger');
            }
            else {
                failPoints = failPoints - 1;
                name.removeClass('is-danger');
            }
            if (!email.val()) {
                failPoints = failPoints + 1;
                email.addClass('is-danger');
            }
            else {
                failPoints = failPoints - 1;
                email.removeClass('is-danger');
            }
            if (!nachricht.val()) {
                failPoints = failPoints + 1;
                nachricht.addClass('is-danger');
            }
            else {
                failPoints = failPoints - 1;
                nachricht.removeClass('is-danger');
            }
            if (!agb) {
                failPoints = failPoints + 1;
                $(document).find('#agb').addClass('is-danger');
            }
            else {
                failPoints = failPoints - 1;
                $(document).find('#agb').removeClass('is-danger');
            }
            if (failPoints == -4) {
                verified = true;
            }
            console.log(failPoints);
            console.log(verified);
            if (verified) {
                $.post('/sendMail', {
                    name: name.val(),
                    email: email.val(),
                    handymodell: handymodell.val() == null ? 'keine angabe' : handymodell.val(),
                    defekt: defekt.val() == null ? 'keine angabe' : defekt.val(),
                    nachricht: nachricht.val()
                }, function (response) {
                    //console.log(response);
                    $(document).find('.successContact').show();
                    $(document).find('.contactForm').hide();
                });
            }
        });
        $(document).on('click', '.next-step', function (e) {
            e.preventDefault();
            var currentStep = $(this).closest('.step');
            var nextStep = currentStep.next('.step');
            if (nextStep.length) {
                currentStep.hide();
                nextStep.show();
            }
        });
        $(document).on('click', '.back-step', function (e) {
            e.preventDefault();
            var currentStep = $(this).closest('.step');
            var prevStep = currentStep.prev('.step');
            if (prevStep.length) {
                currentStep.hide();
                prevStep.show();
            }
        });
        $(document).on('click', '.sendContact', function (e) {
            e.preventDefault();
            var verified = true;
            var name = $('#name');
            var email = $('#email');
            var telefon = $('#telefon');
            // Form validation
            if (!name.val()) {
                verified = false;
                name.addClass('is-danger');
            }
            else {
                name.removeClass('is-danger');
            }
            if (!email.val()) {
                verified = false;
                email.addClass('is-danger');
            }
            else {
                email.removeClass('is-danger');
            }
            if (!telefon.val()) {
                verified = false;
                telefon.addClass('is-danger');
            }
            else {
                telefon.removeClass('is-danger');
            }
            if (verified) {
                // Create FormData object to handle file uploads
                var formData = new FormData();
                formData.append('name', name.val());
                formData.append('email', email.val());
                formData.append('telefon', telefon.val());
                formData.append('marke', $('#marke').val());
                formData.append('gerätename', $('#gerätename').val());
                formData.append('zustand', $('#zustand').val());
                formData.append('zusatzinformationen', $('#zusatzinformationen').val());
                // Handle file uploads
                var fileInput = document.getElementById('fileUpload');
                if (fileInput && fileInput.files && fileInput.files.length > 0) {
                    for (var i = 0; i < fileInput.files.length; i++) {
                        formData.append('files[]', fileInput.files[i]);
                    }
                }
                $.ajax({
                    url: '/sendMailAnkauf',
                    type: 'POST',
                    data: formData,
                    processData: false,
                    contentType: false,
                    success: function (response) {
                        $('.successContact').show();
                        $('.contactForm').hide();
                    }
                });
            }
        });
        $(document).on('click', '.sendContactB2B', function (e) {
            e.preventDefault();
            var verified = false;
            var failPoints = 0;
            var name = $(document).find('#name');
            var email = $(document).find('#email');
            var firma = $(document).find('#firma');
            var tel = $(document).find('#tel');
            var nachricht = $(document).find('#nachricht');
            var agb = $(document).find('#agb').is(':checked');
            if (!name.val()) {
                failPoints = failPoints + 1;
                name.addClass('is-danger');
            }
            else {
                failPoints = failPoints - 1;
                name.removeClass('is-danger');
            }
            if (!email.val()) {
                failPoints = failPoints + 1;
                email.addClass('is-danger');
            }
            else {
                failPoints = failPoints - 1;
                email.removeClass('is-danger');
            }
            if (!nachricht.val()) {
                failPoints = failPoints + 1;
                nachricht.addClass('is-danger');
            }
            else {
                failPoints = failPoints - 1;
                nachricht.removeClass('is-danger');
            }
            if (!agb) {
                failPoints = failPoints + 1;
                $(document).find('#agb').addClass('is-danger');
            }
            else {
                failPoints = failPoints - 1;
                $(document).find('#agb').removeClass('is-danger');
            }
            if (failPoints == -4) {
                verified = true;
            }
            console.log(failPoints);
            console.log(verified);
            if (verified) {
                $.post('/sendMailB2B', {
                    name: name.val(),
                    email: email.val(),
                    firma: firma.val() == null ? 'keine angabe' : firma.val(),
                    tel: tel.val() == null ? 'keine angabe' : tel.val(),
                    nachricht: nachricht.val()
                }, function (response) {
                    //console.log(response);
                    $(document).find('.successContactB2B').show();
                    $(document).find('.contactFormB2B').hide();
                });
            }
        });
        $(document).on('click', '.addReparatur', function (e) {
            e.preventDefault();
            var reparatur = $(document).find('#reparatur');
            //save database
            $.post('/addReparatur', {
                reparatur: reparatur.val()
            }, function (response) {
                window.location.reload();
            });
        });
        $(document).on('click', '.addService', function (e) {
            e.preventDefault();
            var service = $(document).find('#service');
            var preis = $(document).find('#preis');
            //save database
            $.post('/addService', {
                service: service.val(),
                preis: preis.val()
            }, function (response) {
                window.location.reload();
            });
        });
        $(document).on('change', '.changeAccessoriesImg', function (e) {
            var id = $(this).attr('id');
            var file = this.files[0];
            var data = new FormData();
            data.append('file', file, id);
            $.ajax({
                url: "/uploadPictureAccessories",
                type: "POST",
                data: data,
                processData: false,
                contentType: false,
                cache: false,
                success: function (response) {
                    window.location.reload();
                }
            });
        });
        $(document).on('click', '.addAccessories', function (e) {
            e.preventDefault();
            var zubehoer = $(document).find('#zubehoer');
            var ek = $(document).find('#ek');
            var marge = $(document).find('#marge');
            //save database
            $.post('/addAccessories', {
                zubehoer: zubehoer.val(),
                ek: ek.val(),
                marge: marge.val()
            }, function (response) {
                window.location.reload();
            });
        });
        function reloadPageCompletely() {
            // Cache leeren (funktioniert nicht in allen Browsern)
            if (window.caches) {
                caches.keys().then(function (names) {
                    for (var _i = 0, names_1 = names; _i < names_1.length; _i++) {
                        var name_1 = names_1[_i];
                        caches["delete"](name_1);
                    }
                });
            }
            // Seite hart neu laden
            location.href = location.href.split('?')[0] + '?nocache=' + new Date().getTime();
        }
        $(document).on('change', '.changeDeviceImg', function (e) {
            var id = $(this).attr('id');
            var file = this.files[0];
            var data = new FormData();
            data.append('file', file, id);
            $.ajax({
                url: "/uploadPictureDevice",
                type: "POST",
                data: data,
                processData: false,
                contentType: false,
                cache: false,
                success: function (response) {
                    reloadPageCompletely();
                }
            });
        });
        $(document).on('change', '.isPrimary', function (e) {
            var id = $(this).attr('id');
            var primary = $(this).is(":checked");
            //save database
            $.post('/setPrimary', {
                id: id,
                primary: primary
            }, function (response) {
                console.log(response);
                window.location.reload();
            });
        });
        $(document).on('click', '.addPreis', function (e) {
            e.preventDefault();
            var ek = $(document).find('#preisEk');
            var ekcopy = $(document).find('#preisCopyEk');
            var marge = $(document).find('#preisMarge');
            var margecopy = $(document).find('#preisCopyMarge');
            var geraet = $(document).find('#geraetselected').children(":selected").attr("id");
            var reparatur = $(document).find('#reparaturselected').children(":selected").attr("id");
            //save database
            $.post('/addPreis', {
                ek: ek.val(),
                ekcopy: ekcopy.val(),
                marge: marge.val(),
                margecopy: margecopy.val(),
                gerat: geraet,
                repair: reparatur
            }, function (response) {
                // window.location.reload();
                (0, bulma_toast_1.toast)({
                    message: "Erfolgreich hinzugefügt!",
                    type: 'is-success',
                    closeOnClick: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: "top-right",
                    animate: { "in": 'fadeIn', out: 'fadeOut' }
                });
                $(".allPrices").html(response);
            });
        });
        $(document).on('click', '.filterMarke', function (e) {
            e.preventDefault();
            var id = $(this).attr("id");
            var deviceListAll = $(document).find("#deviceList").find('.geraet');
            deviceListAll.hide();
            var deviceList = $(document).find("#deviceList").find('.' + id);
            deviceList.show();
        });
        $(document).on('click', '.archive', function (e) {
            e.preventDefault();
            var id = $(this).attr("id");
            $.post('/archive', {
                id: id
            }, function (response) {
                window.location.href = "/dashboard";
            });
        });
        $(document).on('click', '#refresh', function (e) {
            window.location.reload();
        });
        $(document).on('click', '.addGeraet2', function (e) {
            e.preventDefault();
            var geraet = $(document).find('#geraet');
            var marke = $(document).find('.selectedMarke');
            var type = $(document).find('.selectedType');
            //save database
            $.post('/addGeraet', {
                geraet: geraet.val(),
                marke: marke.val(),
                type: type.val()
            }, function (response) {
                // window.location.reload();
                (0, bulma_toast_1.toast)({
                    message: "Erfolgreich hinzugefügt!",
                    type: 'is-success',
                    closeOnClick: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: "top-right",
                    animate: { "in": 'fadeIn', out: 'fadeOut' }
                });
                $("#deviceList").html(response);
            });
        });
        $(document).on('change', '.changeLogo', function (e) {
            var id = $(this).attr('id');
            var file = this.files[0];
            var data = new FormData();
            data.append('file', file, id);
            $.ajax({
                url: "/uploadPicture",
                type: "POST",
                data: data,
                processData: false,
                contentType: false,
                cache: false,
                success: function (response) {
                    window.location.reload();
                }
            });
        });
        $(document).on('click', '.addMarke', function (e) {
            e.preventDefault();
            var marke = $(document).find('#marke');
            //save database
            $.post('/addMarke', {
                data: marke.val()
            }, function (response) {
                window.location.reload();
            });
        });
        $(document).on('click', '.editPrice', function (e) {
            e.preventDefault();
            var id = $(this).attr('id');
            var input = $(document).find('#order-' + id);
            input.find('.dataInputEk').removeAttr('disabled');
            input.find('.dataInputEkCopy').removeAttr('disabled');
            input.find('.dataInputMarge').removeAttr('disabled');
            input.find('.dataInputMargeCopy').removeAttr('disabled');
            input.find('.savePrice').show();
            $(this).hide();
            console.log(input);
        });
        $(document).on('click', '.savePrice', function (e) {
            e.preventDefault();
            var id = $(this).attr('id');
            var input = $(document).find('#order-' + id);
            var cclass = input.find('#class').val();
            input.find('.dataInputEkCopy').attr('disabled', 'disabled');
            input.find('.dataInputEk').attr('disabled', 'disabled');
            input.find('.dataInputMarge').attr('disabled', 'disabled');
            input.find('.dataInputMargeCopy').attr('disabled', 'disabled');
            input.find('.editPrice').show();
            $(this).hide();
            //save database
            $.post('/saveData', {
                id: id,
                data: null,
                ek: input.find('.dataInputEk').val(),
                ekcopy: input.find('.dataInputEkCopy').val(),
                marge: input.find('.dataInputMarge').val(),
                margecopy: input.find('.dataInputMargeCopy').val(),
                "class": cclass
            }, function (response) {
                (0, bulma_toast_1.toast)({
                    message: "Erfolgreich gespeichert",
                    type: 'is-success',
                    closeOnClick: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: "top-right",
                    animate: { "in": 'fadeIn', out: 'fadeOut' }
                });
            });
        });
        $(document).on('click', '.editService', function (e) {
            e.preventDefault();
            var id = $(this).attr('id');
            var input = $(document).find('#order-' + id);
            input.find('.dataInputServiceName').removeAttr('disabled');
            input.find('.dataInputServicePreis').removeAttr('disabled');
            input.find('.dataInputServiceDesc').removeAttr('disabled');
            input.find('.saveService').show();
            $(this).hide();
        });
        $(document).on('click', '.saveService', function (e) {
            e.preventDefault();
            var id = $(this).attr('id');
            var input = $(document).find('#order-' + id);
            var cclass = input.find('#class').val();
            input.find('.dataInputServiceName').attr('disabled', 'disabled');
            input.find('.dataInputServicePreis').attr('disabled', 'disabled');
            input.find('.dataInputServiceDesc').attr('disabled', 'disabled');
            input.find('.editService').show();
            $(this).hide();
            //save database
            $.post('/saveData', {
                id: id,
                data: null,
                name: input.find('.dataInputServiceName').val(),
                preis: input.find('.dataInputServicePreis').val(),
                desc: input.find('.dataInputServiceDesc').val(),
                "class": cclass
            }, function (response) {
                (0, bulma_toast_1.toast)({
                    message: "Erfolgreich gespeichert",
                    type: 'is-success',
                    closeOnClick: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: "top-right",
                    animate: { "in": 'fadeIn', out: 'fadeOut' }
                });
            });
        });
        $(document).on('click', '.saveAll', function (e) {
            var prices = $(document).find('.allPrices').find('.savePrice');
            prices.each(function (index, item) {
                if ($(this).is(':visible')) {
                    $(this).trigger('click');
                }
            });
            window.location.reload();
        });
        $(document).on('click', '.edit', function (e) {
            e.preventDefault();
            var id = $(this).attr('id');
            var input = $(document).find('#order-' + id);
            input.find('.dataInput').removeAttr('disabled');
            input.find('.dataInputDesc').removeAttr('disabled');
            input.find('.save').show();
            $(this).hide();
        });
        $(document).on('click', '.save', function (e) {
            e.preventDefault();
            var id = $(this).attr('id');
            var input = $(document).find('#order-' + id);
            var cclass = input.find('#class').val();
            input.find('.dataInput').attr('disabled', 'disabled');
            input.find('.dataInputDesc').attr('disabled', 'disabled');
            input.find('.edit').show();
            $(this).hide();
            //save database
            $.post('/saveData', {
                id: id,
                data: input.find('.dataInput').val(),
                desc: input.find('.dataInputDesc').val(),
                "class": cclass
            }, function (response) {
                window.location.reload();
            });
        });
        $(document).on('click', '.delete', function (e) {
            e.preventDefault();
            var id = $(this).attr('id');
            var input = $(document).find('#order-' + id);
            var cclass = input.find('#class').val();
            $.post('/deleteData', {
                id: id,
                "class": cclass
            }, function (response) {
                window.location.reload();
            });
        });
        $(document).on('click', '#maintaince', function (e) {
            e.preventDefault();
            console.log('ts');
            $.post('/system', {
                action: 'maintaince'
            }, function (response) {
                window.location.reload();
            });
        });
        $(document).on('click', '#price', function (e) {
            e.preventDefault();
            console.log('ts');
            $.post('/system', {
                action: 'price'
            }, function (response) {
                window.location.reload();
            });
        });
        $(document).on('click', '.deletePosition', function (e) {
            e.preventDefault();
            var id = $(this).attr('id');
            $.post('/editBasket', {
                id: id,
                action: 'delete'
            }, function (response) {
                window.location.reload();
            });
        });
        $(document).on('click', '.addPosition', function (e) {
            e.preventDefault();
            var id = $(this).attr('id');
            $.post('/editBasket', {
                id: id,
                action: 'add'
            }, function (response) {
                window.location.reload();
            });
        });
        $(document).on('click', '.payLater', function (e) {
            e.preventDefault();
            var form = $(document).find('#repairform');
            var firstname = form.find('#firstname');
            var lastname = form.find('#lastname');
            var street = form.find('#street');
            var streetnumber = form.find('#streetnumber');
            var streetoption = form.find('#streetoption');
            var country = form.find('#country');
            var zipcode = form.find('#zipcode');
            var city = form.find('#city');
            var email = form.find('#email');
            var tel = form.find('#tel');
            var shipping = null;
            var dhlShipping = form.find('#dhl-shipping').is(':checked');
            if (dhlShipping == true) {
                shipping = "dhl";
            }
            else {
                shipping = "noshipping";
            }
            var payment = "cash"; // NUR BEI PAYLATER BAR
            var agb = form.find('#agb').is(':checked');
            var formValidation = 0;
            var formValid = false;
            if (firstname.val() == "") {
                firstname.addClass('is-danger');
                $('#help-firstname').show();
                formValidation = formValidation + 1;
            }
            else {
                firstname.removeClass('is-danger');
                $('#help-firstname').hide();
                formValidation = formValidation - 1;
            }
            if (lastname.val() == "") {
                lastname.addClass('is-danger');
                $('#help-lastname').show();
                formValidation = formValidation + 1;
            }
            else {
                lastname.removeClass('is-danger');
                $('#help-lastname').hide();
                formValidation = formValidation - 1;
            }
            if (street.val() == "") {
                street.addClass('is-danger');
                $('#help-street').show();
                formValidation = formValidation + 1;
            }
            else {
                street.removeClass('is-danger');
                $('#help-street').hide();
                formValidation = formValidation - 1;
            }
            if (streetnumber.val() == "") {
                streetnumber.addClass('is-danger');
                $('#help-streetnumber').show();
                formValidation = formValidation + 1;
            }
            else {
                streetnumber.removeClass('is-danger');
                $('#help-streetnumber').hide();
                formValidation = formValidation - 1;
            }
            // PLZ- und E-Mail-Regex-Definitionen
            var postalCodeRegex = /^[0-9]{5}$/; // Deutsche PLZ: genau 5 Ziffern
            var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Standard-Email-Format
            // Typcasting auf string, um Fehler zu vermeiden
            var zipcodeValue = String(zipcode.val() || "");
            var emailValue = String(email.val() || "");
            if (zipcodeValue === "" || !postalCodeRegex.test(zipcodeValue)) {
                zipcode.addClass('is-danger');
                if (zipcodeValue === "") {
                    $('#help-zipcode').text('Bitte geben Sie eine PLZ ein.').show();
                }
                else {
                    $('#help-zipcode').text('Die PLZ ist ungültig. Sie muss aus 5 Ziffern bestehen.').show();
                }
                formValidation = formValidation + 1;
            }
            else {
                zipcode.removeClass('is-danger');
                $('#help-zipcode').hide();
                formValidation = formValidation - 1;
            }
            if (emailValue === "" || !emailRegex.test(emailValue)) {
                email.addClass('is-danger');
                if (emailValue === "") {
                    $('#help-email').text('Bitte geben Sie eine E-Mail-Adresse ein.').show();
                }
                else {
                    $('#help-email').text('Die E-Mail-Adresse ist ungültig. Bitte überprüfen Sie sie.').show();
                }
                formValidation = formValidation + 1;
            }
            else {
                email.removeClass('is-danger');
                $('#help-email').hide();
                formValidation = formValidation - 1;
            }
            if (city.val() == "") {
                city.addClass('is-danger');
                $('#help-city').show();
                formValidation = formValidation + 1;
            }
            else {
                city.removeClass('is-danger');
                $('#help-city').hide();
                formValidation = formValidation - 1;
            }
            if (!agb) {
                form.find('#agb').addClass('is-danger');
                $('#help-agb').show();
                formValidation = formValidation + 1;
            }
            else {
                form.find('#agb').removeClass('is-danger');
                $('#help-agb').hide();
                formValidation = formValidation - 1;
            }
            if (formValidation == -8) {
                formValid = true;
            }
            if (formValid) {
                //form als array an formPost schicken & dort in die Datenbank mit dem Basket speichern, danach die erfolgreich seite anzeigen.
                var order = {
                    'firstname': firstname.val(),
                    'lastname': lastname.val(),
                    'street': street.val(),
                    'streetnumber': streetnumber.val(),
                    'streetoption': streetoption.val(),
                    'country': country.val(),
                    'zipcode': zipcode.val(),
                    'city': city.val(),
                    'email': email.val(),
                    'tel': tel.val(),
                    'shipping': shipping,
                    'payment': payment
                };
                // document.cookie = 'basket' + '=; expires=Thu, 01-Jan-70 00:00:01 GMT;';
                $.post('/formPost', {
                    order: JSON.stringify(order)
                }, function (response) {
                    $.post('/removeBasket', {}, function (response3) {
                        window.location.href = "/erfolgreich/" + response.ordernumber;
                    });
                });
            }
        });
        $(document).on('click', '.payNow', function (e) {
            e.preventDefault();
            var form = $(document).find('#repairform');
            var firstname = form.find('#firstname');
            var lastname = form.find('#lastname');
            var street = form.find('#street');
            var streetnumber = form.find('#streetnumber');
            var streetoption = form.find('#streetoption');
            var country = form.find('#country');
            var zipcode = form.find('#zipcode');
            var city = form.find('#city');
            var email = form.find('#email');
            var tel = form.find('#tel');
            var shipping = null;
            var dhlShipping = form.find('#dhl-shipping').is(':checked');
            if (dhlShipping == true) {
                shipping = "dhl";
            }
            else {
                shipping = "noshipping";
            }
            var payment = null;
            var vorkasse = form.find('#vorkasse').is(':checked');
            var payCash = form.find('#payCash').is(':checked');
            if (vorkasse != false) {
                payment = "vorkasse";
            }
            else {
                payment = "cash";
            }
            var agb = form.find('#agb').is(':checked');
            var formValidation = 0;
            var formValid = false;
            if (firstname.val() == "") {
                firstname.addClass('is-danger');
                $('#help-firstname').show();
                formValidation = formValidation + 1;
            }
            else {
                firstname.removeClass('is-danger');
                $('#help-firstname').hide();
                formValidation = formValidation - 1;
            }
            if (lastname.val() == "") {
                lastname.addClass('is-danger');
                $('#help-lastname').show();
                formValidation = formValidation + 1;
            }
            else {
                lastname.removeClass('is-danger');
                $('#help-lastname').hide();
                formValidation = formValidation - 1;
            }
            if (street.val() == "") {
                street.addClass('is-danger');
                $('#help-street').show();
                formValidation = formValidation + 1;
            }
            else {
                street.removeClass('is-danger');
                $('#help-street').hide();
                formValidation = formValidation - 1;
            }
            if (streetnumber.val() == "") {
                streetnumber.addClass('is-danger');
                $('#help-streetnumber').show();
                formValidation = formValidation + 1;
            }
            else {
                streetnumber.removeClass('is-danger');
                $('#help-streetnumber').hide();
                formValidation = formValidation - 1;
            }
            // PLZ- und E-Mail-Regex-Definitionen
            var postalCodeRegex = /^[0-9]{5}$/; // Deutsche PLZ: genau 5 Ziffern
            var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Standard-Email-Format
            // Typcasting auf string, um Fehler zu vermeiden
            var zipcodeValue = String(zipcode.val() || "");
            var emailValue = String(email.val() || "");
            if (zipcodeValue === "" || !postalCodeRegex.test(zipcodeValue)) {
                zipcode.addClass('is-danger');
                if (zipcodeValue === "") {
                    $('#help-zipcode').text('Bitte geben Sie eine PLZ ein.').show();
                }
                else {
                    $('#help-zipcode').text('Die PLZ ist ungültig. Sie muss aus 5 Ziffern bestehen.').show();
                }
                formValidation = formValidation + 1;
            }
            else {
                zipcode.removeClass('is-danger');
                $('#help-zipcode').hide();
                formValidation = formValidation - 1;
            }
            if (emailValue === "" || !emailRegex.test(emailValue)) {
                email.addClass('is-danger');
                if (emailValue === "") {
                    $('#help-email').text('Bitte geben Sie eine E-Mail-Adresse ein.').show();
                }
                else {
                    $('#help-email').text('Die E-Mail-Adresse ist ungültig. Bitte überprüfen Sie sie.').show();
                }
                formValidation = formValidation + 1;
            }
            else {
                email.removeClass('is-danger');
                $('#help-email').hide();
                formValidation = formValidation - 1;
            }
            if (city.val() == "") {
                city.addClass('is-danger');
                $('#help-city').show();
                formValidation = formValidation + 1;
            }
            else {
                city.removeClass('is-danger');
                $('#help-city').hide();
                formValidation = formValidation - 1;
            }
            if (!agb) {
                form.find('#agb').addClass('is-danger');
                $('#help-agb').show();
                formValidation = formValidation + 1;
            }
            else {
                form.find('#agb').removeClass('is-danger');
                $('#help-agb').hide();
                formValidation = formValidation - 1;
            }
            if (formValidation == -8) {
                formValid = true;
            }
            if (formValid) {
                //form als array an formPost schicken & dort in die Datenbank mit dem Basket speichern, danach die erfolgreich seite anzeigen.
                var order = {
                    'firstname': firstname.val(),
                    'lastname': lastname.val(),
                    'street': street.val(),
                    'streetnumber': streetnumber.val(),
                    'streetoption': streetoption.val(),
                    'country': country.val(),
                    'zipcode': zipcode.val(),
                    'city': city.val(),
                    'email': email.val(),
                    'tel': tel.val(),
                    'shipping': shipping,
                    'payment': payment
                };
                // document.cookie = 'basket' + '=; expires=Thu, 01-Jan-70 00:00:01 GMT;';
                // Prüfen, ob das Element existiert und nicht leer ist
                var comboRabattText = $("#comboRabatt").text().trim();
                var comboRabattValue = parseFloat(comboRabattText.replace('€', '').replace(',', '.').replace('-', '').trim()) || 0;
                var comboRabatt = comboRabattValue > 0;
                $.post('/formPost', {
                    order: JSON.stringify(order),
                    shipping: dhlShipping,
                    combo: comboRabatt
                }, function (response) {
                    $.post('/payment', {
                        orderNumber: response.ordernumber
                    }, function (response2) {
                        location.href = response2.url;
                        //console.log(response2)
                        /*$.post('/removeBasket', {
                        }, (response3) => {
                            window.location.href ="/erfolgreich/"+response.ordernumber;
                        });*/
                    });
                });
            }
        });
        $(document).on('click', '#pin-tab', function (e) {
            e.preventDefault();
            var musterTab = $(document).find('#muster-tab');
            var pinTab = $(document).find('#pin-tab');
            var musterContainer = $(document).find('#muster');
            var pinContainer = $(document).find('#pin');
            musterTab.removeClass('is-active');
            pinTab.addClass('is-active');
            musterContainer.hide();
            pinContainer.show();
        });
        $(document).on('click', '#muster-tab', function (e) {
            e.preventDefault();
            var musterTab = $(document).find('#muster-tab');
            var pinTab = $(document).find('#pin-tab');
            var musterContainer = $(document).find('#muster');
            var pinContainer = $(document).find('#pin');
            musterTab.addClass('is-active');
            pinTab.removeClass('is-active');
            musterContainer.show();
            pinContainer.hide();
        });
        var dhlRadio = document.getElementById("dhl-shipping");
        var noShippingRadio = document.getElementById("noshipping");
        var payLaterButton = document.getElementById("payLater");
        var payNowButton = document.getElementById("payNow");
        var dhlSum = document.getElementById("dhlSum");
        // Elemente auswählen
        var basketSum = document.getElementById("basketSum");
        var basketTaxes = document.getElementById("basketTaxes");
        // Funktion, um den Textinhalt zu einem numerischen Wert zu konvertieren
        function parsePrice(priceText) {
            if (!priceText)
                return 0;
            // Entferne zuerst das Währungssymbol und Leerzeichen
            var cleanText = priceText.replace('€', '').trim();
            // Tausendertrennzeichen (Punkt) entfernen und Komma durch Punkt ersetzen
            return parseFloat(cleanText.replace(/\./g, '').replace(',', '.'));
        }
        // Funktion, um den Preis in das gewünschte Format zurückzuformatieren
        function formatPrice(price) {
            // Verwende toLocaleString für korrekte Formatierung mit Tausendertrennzeichen
            return price.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }) + ' €';
        }
        // Versandkosten und Steuersatz
        var shippingCost = 5.90;
        var taxRate = 1.19;
        // Boolean-Flag für Versandkosten
        var shipping = false;
        // Originale Gesamtsumme initialisieren
        var originalSum = 0;
        if (basketSum) {
            originalSum = parsePrice(basketSum.textContent || '0');
            console.log("Originalsumme:", originalSum);
        }
        // Berechnungslogik
        function updatePrices() {
            if (!basketSum || !basketTaxes)
                return;
            // Starte mit der originalen Gesamtsumme
            var currentSum = originalSum;
            // Wenn Versandkosten hinzugerechnet werden sollen
            if (shipping) {
                currentSum += shippingCost;
            }
            // Steuern berechnen (z. B. 19% von der Gesamtsumme)
            var taxes = currentSum - (currentSum / taxRate);
            console.log("Aktualisierte Summe:", currentSum, "Steuern:", taxes);
            // Preise aktualisieren
            basketSum.textContent = formatPrice(currentSum);
            basketTaxes.textContent = formatPrice(taxes);
        }
        function updatePaymentOptions() {
            if (!dhlRadio || !noShippingRadio || !payLaterButton || !payNowButton || !dhlSum) {
                console.error("Ein oder mehrere Elemente wurden nicht gefunden!");
                return;
            }
            if (dhlRadio.checked) {
                payLaterButton.style.display = "none";
                payNowButton.style.display = "";
                dhlSum.style.display = "table-row";
                shipping = true;
            }
            else if (noShippingRadio.checked) {
                payLaterButton.style.display = "";
                payNowButton.style.display = "";
                dhlSum.style.display = "none";
                shipping = false;
            }
            // Preise in jedem Fall aktualisieren
            updatePrices();
        }
        if (!dhlRadio || !noShippingRadio || !payLaterButton || !payNowButton) {
            console.error("Ein oder mehrere Elemente wurden nicht gefunden!");
        }
        else {
            // Initialer Zustand
            updatePaymentOptions();
            // Event-Listener für Änderungen an den Radio-Buttons
            dhlRadio.addEventListener("change", updatePaymentOptions);
            noShippingRadio.addEventListener("change", updatePaymentOptions);
        }
        // Functions to open and close a modal
        function openModal($el) {
            $el.classList.add('is-active');
        }
        function closeModal($el) {
            $el.classList.remove('is-active');
        }
        function closeAllModals() {
            (document.querySelectorAll('.modal') || []).forEach(function ($modal) {
                closeModal($modal);
            });
        }
        // Add a click event on buttons to open a specific modal
        (document.querySelectorAll('.js-modal-trigger') || []).forEach(function ($trigger) {
            var modal = $trigger.dataset.target;
            var $target = document.getElementById(modal);
            if ($target) {
                $trigger.addEventListener('click', function (e) {
                    var _a;
                    // Überprüfe, ob nur "Auf Anfrage"-Reparaturen ausgewählt sind oder Gesamtpreis 0€
                    var nurAufAnfrageAusgewaehlt = pruefeNurAufAnfrageAusgewaehlt();
                    var gesamtPreisText = ((_a = document.getElementById('gesamtPreis')) === null || _a === void 0 ? void 0 : _a.textContent) || '';
                    if (nurAufAnfrageAusgewaehlt || gesamtPreisText.trim() === '0,00 €') {
                        e.preventDefault(); // Verhindere das Öffnen des Standard-Modals
                        var kontaktModal = document.getElementById('modal-kontaktformular');
                        if (kontaktModal) {
                            openModal(kontaktModal);
                        }
                    }
                    else {
                        // Normales Verhalten - öffne das Standard-Modal für PIN/Muster
                        openModal($target);
                    }
                });
            }
        });
        // Funktion zum Prüfen, ob nur "Auf Anfrage"-Reparaturen ausgewählt sind
        function pruefeNurAufAnfrageAusgewaehlt() {
            // Alle ausgewählten Reparaturen
            var ausgewaehlteReparaturen = $('#selectRepair input[type="checkbox"]:checked');
            if (ausgewaehlteReparaturen.length === 0)
                return false;
            // Prüfe, ob alle ausgewählten Reparaturen "Auf Anfrage" sind
            var alleAufAnfrage = true;
            ausgewaehlteReparaturen.each(function () {
                var preisElement = $(this).next().find('.price-tag');
                if (preisElement.length && preisElement.text().trim() !== 'Auf Anfrage') {
                    alleAufAnfrage = false;
                    return false; // Schleife abbrechen
                }
            });
            return alleAufAnfrage;
        }
        // Add a click event on various child elements to close the parent modal entfernt: , .modal-card-foot .button
        (document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .deleteModal') || []).forEach(function ($close) {
            var $target = $close.closest('.modal');
            if ($target) {
                $close.addEventListener('click', function () {
                    closeModal($target);
                });
            }
        });
        // Add a keyboard event to close all modals
        document.addEventListener('keydown', function (event) {
            if (event.key === "Escape") {
                closeAllModals();
            }
        });
        var checkboxes = document.querySelectorAll('.serviceRepairInput');
        var fehlerbeschreibung = document.getElementById('fehlerbeschreibung');
        var addToBasketButton = document.querySelector('.addToBasket');
        var fehlerPflichtfeldHinweis = document.getElementById('fehler-pflichtfeld-hinweis');
        var fehlerBeschreibungFehler = document.getElementById('fehlerbeschreibung-fehler');
        // Funktion zur Überprüfung, ob mindestens eine Checkbox ausgewählt ist
        var isAnyCheckboxChecked = function () {
            return Array.from(checkboxes).some(function (checkbox) { return checkbox.checked; });
        };
        // Event Listener für die Checkboxes
        checkboxes.forEach(function (checkbox) {
            checkbox.addEventListener('change', function () {
                if (isAnyCheckboxChecked()) {
                    fehlerbeschreibung.setAttribute('required', 'true');
                    fehlerPflichtfeldHinweis.style.display = 'inline'; // Pflichtfeldhinweis anzeigen
                }
                else {
                    fehlerbeschreibung.removeAttribute('required');
                    fehlerPflichtfeldHinweis.style.display = 'none'; // Pflichtfeldhinweis ausblenden
                    fehlerBeschreibungFehler.style.display = 'none'; // Fehlertext ausblenden
                }
            });
        });
        // Event Listener für den Button "In den Warenkorb"
        addToBasketButton.addEventListener('click', function (e) {
            if (isAnyCheckboxChecked() && !fehlerbeschreibung.value.trim()) {
                e.preventDefault();
                fehlerBeschreibungFehler.style.display = 'block'; // Fehlertext anzeigen
                fehlerbeschreibung.focus();
            }
            else {
                fehlerBeschreibungFehler.style.display = 'none'; // Fehlertext ausblenden
                var basket_1 = [];
                $('#positions').find(':input').each(function (index) {
                    if ($(this).is(':checked')) {
                        var value = $(this).val();
                        basket_1.push(value);
                    }
                });
                var fehlerbeschreibung_1 = $(document).find('#fehlerbeschreibung');
                var entsperrmuster = $(document).find('#entsperrmuster');
                var farbe = $(document).find('#farbeInput');
                $.post('/addToBasket', {
                    basket: JSON.stringify(basket_1),
                    text: JSON.stringify({ 'fehlerbeschreibung': fehlerbeschreibung_1.val(), 'entsperrmuster': entsperrmuster.val(), 'farbe': farbe.val() })
                }, function (response) {
                    window.location.href = '/reparaturkorb';
                    // console.log(response);
                });
            }
        });
        // Fehlertext ausblenden, wenn der Benutzer das Textarea ausfüllt
        fehlerbeschreibung.addEventListener('input', function () {
            if (fehlerbeschreibung.value.trim()) {
                fehlerBeschreibungFehler.style.display = 'none';
            }
        });
        $(document).on('click', '#anfrage-senden', function (e) {
            e.preventDefault();
            // Daten aus dem Kontaktformular sammeln
            var name = $('#kontakt-name').val();
            var email = $('#kontakt-email').val();
            var telefon = $('#kontakt-telefon').val();
            var anfrage = $('#kontakt-anfrage').val();
            // Validierung
            if (!name || !email || !telefon || !anfrage) {
                $('#kontaktformular-fehler').show();
                return;
            }
            // Sammle ausgewählte Reparaturen für die Anfrage
            var ausgewaehlteReparaturen = [];
            $('#selectRepair input[type="checkbox"]:checked').each(function () {
                var label = $(this).next();
                var reparaturName = label.find('.repair-title h3').html() || '';
                ausgewaehlteReparaturen.push(reparaturName);
            });
            // Anfrage-Daten an den Server senden
            $.post('/sendMailAnfragePreis', {
                name: name,
                email: email,
                telefon: telefon,
                anfrage: anfrage,
                geraet: $('.geraetName').text().trim(),
                marke: $('.geraetMarke').text().trim(),
                reparaturen: JSON.stringify(ausgewaehlteReparaturen)
            }, function (response) {
                if (response.status === 'ok') {
                    // Formular ausblenden und Erfolgsmeldung anzeigen
                    var modalContent = $('#modal-kontaktformular .modal-card-body');
                    var modalFooter = $('#modal-kontaktformular .modal-card-foot');
                    // Speichere den ursprünglichen Inhalt
                    var originalContent = modalContent.html();
                    var originalFooter = modalFooter.html();
                    // Ersetze mit Erfolgsmeldung und Ladeanimation
                    modalContent.html("\n                        <div class=\"has-text-centered\">\n                            <div class=\"mb-4\">\n                                <i class=\"fa-solid fa-circle-check\" style=\"color: #4CAF50; font-size: 4rem;\"></i>\n                            </div>\n                            <h3 class=\"title is-4\">Vielen Dank f\u00FCr Ihre Anfrage!</h3>\n                            <p class=\"mb-4\">Wir werden uns so schnell wie m\u00F6glich bei Ihnen melden.</p>\n                            <div class=\"loading-spinner mt-4\">\n                                <div class=\"loader\"></div>\n                                <p class=\"mt-2\">Sie werden in wenigen Sekunden weitergeleitet...</p>\n                            </div>\n                        </div>\n                    ");
                    // Leere den Footer
                    modalFooter.html('');
                    // Füge CSS für die Ladeanimation hinzu
                    $('head').append("\n                        <style>\n                            .loader {\n                                border: 5px solid #f3f3f3;\n                                border-radius: 50%;\n                                border-top: 5px solid #4CAF50;\n                                width: 50px;\n                                height: 50px;\n                                animation: spin 1s linear infinite;\n                                margin: 0 auto;\n                            }\n                            \n                            @keyframes spin {\n                                0% { transform: rotate(0deg); }\n                                100% { transform: rotate(360deg); }\n                            }\n                        </style>\n                    ");
                    // Nach 5 Sekunden zur Startseite weiterleiten
                    setTimeout(function () {
                        window.location.href = '/';
                    }, 5000);
                }
                else {
                    $('#kontaktformular-fehler').text('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.');
                    $('#kontaktformular-fehler').show();
                }
            });
        });
    };
    return Main;
}());
$(function () {
    new Main();
});
